import React from "react";
import { useNavigate } from "react-router-dom";

const MainGrid = () => {
  const navigate = useNavigate();

  return (
    <div className="main-grid">
      <div className="main-grid-row">
        {" "}
        <div
          className="main-grid-square main-grid-square-first"
          onClick={() => {
            navigate("/unleashed-beauty", {
              state: {},
            });
          }}
        >
          {" "}
          <div className="grid-title">
            <p>Unleashed</p>
            <p>Beauty</p>
          </div>
        </div>
        <div
          className="main-grid-square main-grid-square-second"
          onClick={() => {
            navigate("/cryoskin", {
              state: {},
            });
          }}
        >
          {" "}
          <p className="">&nbsp;</p>
          <div className="grid-title grid-title-cryoskin">
            <p>CRYOSKIN</p>
            <p className="grid-title-cryoskin-subtitle">Slim. Tone. Lift.</p>
          </div>
        </div>
        <div
          className="main-grid-square main-grid-square-third"
          onClick={() => {
            navigate("/sculptpod", {
              state: {},
            });
          }}
        >
          {" "}
          <p className="">&nbsp;</p>
          <div className="grid-title grid-title-sculpt-pod">
            <p>Sculpt Pod</p>
          </div>
        </div>
      </div>
      <div className="main-grid-row">
        {" "}
        <div
          className="main-grid-square main-grid-square-fourth"
          onClick={() => {
            navigate("/salon", {
              state: {},
            });
            // window.open("https://www.aidiles.com", "_blank")?.focus();
          }}
        >
          {" "}
          <p className="">&nbsp;</p>
          <div className="grid-title grid-title-hair-salon">
            {/* <span className="no-scroll">H</span>
            <span className="no-scroll">a</span>
            <span className="no-scroll">i</span>
            <span className="no-scroll">r</span>
            <span className="no-scroll">&nbsp;</span> */}
            <span className="no-scroll">S</span>
            <span className="no-scroll">a</span>
            <span className="no-scroll">l</span>
            <span className="no-scroll">o</span>
            <span className="no-scroll">n</span>
          </div>
        </div>{" "}
        <div
          className="main-grid-square main-grid-square-fifth"
          onClick={() => {
            window.open("https://www.ronaifyboutique.com", "_blank");
          }}
        >
          {" "}
          <p className="">&nbsp;</p>
          <div className="grid-title grid-title-boutique">
            <span>B</span>
            <span>o</span>
            <span>u</span>
            <span>t</span>
            <span>i</span>
            <span>q</span>
            <span>u</span>
            <span>e</span>
          </div>
        </div>{" "}
        <div
          className="main-grid-square main-grid-square-sixth"
          onClick={() => {
            // window.alert("This page is under construction!");
            navigate("/joey", { state: {} });
          }}
        >
          <div className="grid-title grid-title-joey">
            <div>&nbsp;</div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="medium-text">In Loving Memory</p>
              <p className="medium-text">Joseph Francis Ribeiro</p>
              <p className="medium-text ">May 30, 1993 - April 8, 2022</p>
            </div>
            {/* <p className="grid-title-infusions">Injections</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainGrid;
