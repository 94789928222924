import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { kickUnauthorized } from "../../util/kickUnauthorized";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const AppointmentViewer = () => {
  const navigate = useNavigate();

  const [formattedAppts, setFormattedAppts] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const res = await axios.post(
          "/api/v1/check-authenticated",
          {},
          axiosConfig
        );

        if (!res || !res.data || !res.data.success) {
          throw new Error("unauthorized request!");
        } else {
          // console.log(res.data);
          if (res.data.appointments.length) {
            const apptsWithPrettyDate = [];

            res.data.appointments.forEach((fullAppt) => {
              const starter = new Date(
                fullAppt.appointmentDate
              ).toLocaleString();

              if (starter) {
                const prettyDate = `${starter.split(":")[0]}:${
                  starter.split(":")[1]
                }${starter.split(":")[2].split(" ")[1]}`;

                apptsWithPrettyDate.push({
                  ...fullAppt,
                  prettyDate,
                });
              }
            });

            setFormattedAppts(apptsWithPrettyDate);
          }
        }
      } catch (error) {
        console.log(error);
        kickUnauthorized();
      }
    })();
  }, []);

  return (
    <div className="container pt-5 pb-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="medium-large-text">Appointment Viewer</p>
        {formattedAppts &&
          formattedAppts.map((fA, idx) => {
            return (
              <div key={idx} className="d-flex">
                <div className="mt-3 small-text d-flex flex-column justify-content-center align-items-center d-flex">
                  {fA.serviceName && fA.serviceName === "sculpt-pod" && (
                    <div>Sculpt Pod</div>
                  )}

                  <div>{fA.prettyDate}</div>
                </div>
                <div className="m-3"></div>
                <div
                  className="button-small d-flex justify-content-center align-items-center mt-3 small-text"
                  onClick={() => {
                    navigate("/appointment-editor", {
                      state: {
                        nextScheduledAppointment: fA,
                        prettyDate: fA.prettyDate,
                        from: "appointment-viewer",
                      },
                    });
                  }}
                >
                  Modify
                </div>
              </div>
            );
          })}
        <div
          className="button-medium fixed-300-width center-text mt-3 mb-5"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          &lt;&nbsp;Go Back
        </div>
      </div>
    </div>
  );
};

export default AppointmentViewer;
