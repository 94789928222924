const viewContact = function (navigate) {
  // console.log("execute viewContact");
  const contactSection = document.getElementById("contact");

  if (contactSection) {
    contactSection.scrollIntoView();
  } else {
    // console.log("location 3 setting state");
    navigate("/menu", {
      state: { from: "contact-mobile-nav" },
    });
  }
};

export default viewContact;
