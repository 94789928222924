import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import StupidElement from "./StupidElement";
import MobileNav from "./MobileNav";
import viewContact from "../util/viewContact";

const AboutRonaify = () => {
  const navigate = useNavigate();
  // const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".ronaify-about-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="ronaify-about-top fade-in pb-5">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="section-heading section-heading-about section-heading-text  mb-5">
        RONAIFY
      </div>
      <div className="container ">
        <div className="photo-paragraph">
          <img src="/about-content-1.png" alt="decorations" />
          <div className="info-text photo-paragraph-text">
            <p className="mt-3">
              Ronaify Beauty Boutique is a place dedicated to empowering people
              to UNLEASH and embrace their inner beauty. Whether that means body
              contouring, skin care, cryo facial, custom color, or something as
              simple as a blowout, we want every experience to be an opportunity
              to renew! You only have one life to live, so make it count!
            </p>
          </div>
        </div>

        <div className="photo-paragraph photo-paragraph-2">
          <div className="info-text photo-paragraph-text photo-paragraph-text-2">
            <p className="mt-3">
              Ronaify Beauty Boutique wants you to unleash your beauty and
              change the way you feel about yourself by changing how you think
              about yourself, making you feel beautiful and confident in your
              own skin. We are a one-stop beauty shop, so call today to take
              your self care to the next level and continue your unique journey.
            </p>
          </div>
          <img src="/about-content-2.png" alt="more-decorations" />
        </div>

        <div className="photo-with-text photo-with-text-about">
          <img src="/mirror-large.png" alt="large-mirror" />
          <button
            className="infopage-button"
            onClick={() => {
              navigate("/menu", { state: {} });
            }}
          >
            Main Menu
          </button>
        </div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"about"} />}
    </div>
  );
};

export default AboutRonaify;
