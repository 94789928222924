import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const MobileNav = ({ currentPage }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // console.log(currentPage);

    const contactTrigger = document.querySelector(".contact-trigger");

    if (contactTrigger) {
      const treatmentsMobileNav = document.querySelector(
        ".mobile-nav-treatments"
      );
      const contactMobileNav = document.querySelector(".mobile-nav-contact");

      if (window.scrollY >= contactTrigger.offsetTop) {
        // highlight contact
        if (treatmentsMobileNav && contactMobileNav) {
          // console.log("switch-gold-text to contact");
          treatmentsMobileNav.classList.remove("gold-text");
          contactMobileNav.classList.add("gold-text");
        }
      } else {
        // highlight treatments

        if (treatmentsMobileNav && contactMobileNav) {
          contactMobileNav.classList.remove("gold-text");
          treatmentsMobileNav.classList.add("gold-text");
        }
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="mobile-nav-full">
      {currentPage === "menu" ? (
        <p
          className="mobile-nav-item mobile-nav-treatments gold-text"
          onClick={() => {
            if (currentPage && currentPage === "menu") {
              const mobileNavToggle =
                document.getElementById("mobile-nav-access");
              if (mobileNavToggle) {
                mobileNavToggle.click();
                navigate("/menu", { state: { from: "treatments-mobile-nav" } });
              } else {
                navigate("/menu", { state: { from: "treatments-mobile-nav" } });
              }
            } else {
              navigate("/menu", { state: { from: "treatments-mobile-nav" } });
            }
          }}
        >
          Treatments
        </p>
      ) : (
        <p
          className="mobile-nav-item mobile-nav-treatments"
          onClick={() => {
            if (window.location.href.endsWith("/menu")) {
              navigate("/menu", { state: { from: "treatments-mobile-nav" } });
            } else {
              navigate("/menu", {
                state: {
                  from: "treatments-mobile-nav",
                },
              });
            }
          }}
        >
          Treatments
        </p>
      )}

      {currentPage === "gallery" ? (
        <p
          className="mobile-nav-item mobile-nav-gallery gold-text"
          onClick={() => {
            navigate("/gallery", { state: { from: "mobile-nav" } });
          }}
        >
          Gallery
        </p>
      ) : (
        <p
          className="mobile-nav-item mobile-nav-gallery"
          onClick={() => {
            navigate("/gallery", { state: { from: "mobile-nav" } });
          }}
        >
          Gallery
        </p>
      )}

      <p
        className="mobile-nav-item mobile-nav-contact"
        onClick={() => {
          if (currentPage && currentPage === "menu") {
            const mobileNavToggle =
              document.getElementById("mobile-nav-access");
            if (mobileNavToggle) {
              // console.log("location 1 setting state");
              navigate("/menu", {
                state: { from: "contact-mobile-nav" },
              });
              mobileNavToggle.click();
            }
          } else {
            // console.log("location 2 setting state");
            navigate("/menu", {
              state: { from: "contact-mobile-nav" },
            });
          }
        }}
      >
        Contact
      </p>
      {currentPage === "booking" ? (
        <p
          className="mobile-nav-item mobile-nav-booking gold-text"
          onClick={() => {
            // TEMPORARY CODE:
            window
              .open("https://www.vagaro.com/ronaifybeautyboutique/", "_blank")
              .focus();

            // FUTURE CODE:
            // if (currentPage && currentPage === "booking") {
            //   const mobileNavToggle =
            //     document.getElementById("mobile-nav-access");
            //   if (mobileNavToggle) {
            //     mobileNavToggle.click();
            //   } else {
            //     window.location.reload();
            //   }
            // } else {
            //   navigate("/booking", { state: {  } });
            //   //
            // }
          }}
        >
          Booking
        </p>
      ) : (
        <p
          className="mobile-nav-item mobile-nav-booking"
          onClick={() => {
            // TEMPORARY CODE:
            window
              .open("https://www.vagaro.com/ronaifybeautyboutique/", "_blank")
              .focus();

            // FUTURE CODE:
            // navigate("/booking", { state: {  } });
          }}
        >
          Booking
        </p>
      )}
    </div>
  );
};

export default MobileNav;
