import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import viewContact from "../../util/viewContact";
import MobileNav from "../MobileNav";

const PaymentOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [totalCurrentPrice, setTotalCurrentPrice] = useState(0);

  useEffect(() => {
    const top = document.querySelector(".ronaify-about-top");

    if (top) {
      top.scrollIntoView();
    }

    console.log(location);

    switch (location.state.packageName) {
      case "sculpt-pod-super-credits":
        setTotalCurrentPrice(
          parseInt(location.state.numberOfSuperCredits) * 139
        );

      default:
        break;
    }

    // what should be the totalCurrentPrice threshold for Pay Monthly to become available (for Super Credits packages)?

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  const navigator = (e) => {
    switch (location.state.packageName) {
      case "sculpt-pod-super-credits":
        navigate("/sculpt-pod-disclosure", {
          state: {
            from: "payment-options",
            packageName: "sculpt-pod-super-credits",
            numberOfSuperCredits: location.state.numberOfSuperCredits,
            paymentType: e.currentTarget.id,
          },
        });
        break;

      case "12-month-sculpt-pod":
        navigate("/sculpt-pod-disclosure", {
          state: {
            from: "payment-options",
            packageName: "12-month-sculpt-pod",
            numberOfSuperCredits: 0,
            paymentType: e.currentTarget.id,
          },
        });
        break;

      case "6-month-sculpt-pod":
        navigate("/sculpt-pod-disclosure", {
          state: {
            from: "payment-options",
            packageName: "6-month-sculpt-pod",
            numberOfSuperCredits: 0,
            paymentType: e.currentTarget.id,
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="booking-conditional">
        {" "}
        <h1 className="medium-text">Payment Options</h1>
        <div className="booking-conditional-buttons">
          {" "}
          <h2
            className="book-now-button center-text"
            id="pay-now"
            onClick={navigator}
          >
            Pay Now
          </h2>
          <h2
            className="book-now-button center-text"
            id="pay-monthly"
            onClick={navigator}
          >
            Pay Monthly
          </h2>
          <h2
            className="book-now-button center-text"
            onClick={() => {
              if (
                location &&
                location.state &&
                location.state.from &&
                location.state.from === "buy-user-supercredits"
              ) {
                navigate("/buy-supercredits");
              } else {
                navigate("/booking-options", { state: location.state });
              }
            }}
          >
            &lt;&nbsp;Go Back
          </h2>
        </div>
        <div>&nbsp;</div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"payment-options"} />}
    </div>
  );
};

export default PaymentOptions;
