import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = ({
  setMobileNavStatus,
  setMobileNavStatusTrigger,
  viewContact,
}) => {
  const navigate = useNavigate();

  const mobileNav = useRef();
  const mobileNavD1 = useRef();
  const mobileNavD2 = useRef();
  const mobileNavD3 = useRef();

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.endsWith("menu")) {
      const treatments = document.querySelector(".treatments-nav");
      treatments.classList.add("constant-underline");
    } else if (currentUrl.endsWith("booking")) {
      const booking = document.querySelector(".booking-nav");
      booking.classList.add("constant-underline");
    }
  }, []);

  return (
    <div className="navbar">
      <p className="hide-at-1300">&nbsp;</p>
      <p className="show-at-800 dummy-nav">&nbsp;</p>
      <p
        className="gold-underline treatments-nav"
        onClick={() => {
          if (window.location.href.endsWith("/menu")) {
            const t = document.querySelector(".main-menu");

            t.scrollIntoView();
          } else {
            navigate("/menu", {
              state: { from: "treatments-nav" },
            });
          }
        }}
      >
        Treatments
      </p>
      <p
        className="gold-underline"
        onClick={() => {
          navigate("/gallery", { state: { from: "desktop-nav" } });
        }}
      >
        Gallery
      </p>
      <img
        className="navbar-image"
        src="/dark-logo-web-edited-v2.png"
        alt="dark-logo"
        onClick={() => {
          if (window.location.href.endsWith("/menu")) {
            const t = document.querySelector(".main-menu");

            t.scrollIntoView();
          } else {
            navigate("/menu", {
              state: { from: "logo-nav" },
            });
          }
        }}
      />
      <p
        className="gold-underline contact-nav"
        onClick={() => {
          viewContact(navigate);
        }}
      >
        Contact
      </p>
      <p
        className="gold-underline booking-nav"
        onClick={() => {
          // TEMPORARY CODE:
          window
            .open("https://www.vagaro.com/ronaifybeautyboutique/", "_blank")
            ?.focus();

          // PERMANENT CODE:
          // navigate("/booking", { state: {  } });
        }}
      >
        Booking
      </p>
      <p className="hide-at-1300">&nbsp;</p>

      <div
        className="mobile-nav show-at-800"
        ref={mobileNav}
        id="mobile-nav-access"
        onClick={() => {
          if (mobileNav && mobileNav.current && mobileNav.current.classList) {
            if (
              Array.from(mobileNav.current.classList).includes("mobile-nav")
            ) {
              setMobileNavStatus(true);
              mobileNav.current.classList.remove("mobile-nav");
              mobileNav.current.classList.add("mobile-nav-open");

              mobileNavD1.current.classList.remove("mobile-nav-d1");
              mobileNavD1.current.classList.add("mobile-nav-open-d1");

              mobileNavD2.current.classList.remove("mobile-nav-d2");
              mobileNavD2.current.classList.add("mobile-nav-open-d2");

              mobileNavD3.current.classList.remove("mobile-nav-d3");
              mobileNavD3.current.classList.add("mobile-nav-open-d3");
            } else if (
              Array.from(mobileNav.current.classList).includes(
                "mobile-nav-open"
              )
            ) {
              mobileNav.current.classList.remove("mobile-nav-open");
              mobileNav.current.classList.add("mobile-nav");

              mobileNavD1.current.classList.remove("mobile-nav-open-d1");
              mobileNavD1.current.classList.add("mobile-nav-animated-d1");
              mobileNavD1.current.classList.add("mobile-nav-d1");

              mobileNavD2.current.classList.remove("mobile-nav-open-d2");
              mobileNavD2.current.classList.add("mobile-nav-animated-d2");

              mobileNavD3.current.classList.remove("mobile-nav-open-d3");
              mobileNavD3.current.classList.add("mobile-nav-animated-d3");
              mobileNavD3.current.classList.add("mobile-nav-d3");

              // here we need a way of knowing if it was contact or treatment that was clicked in the mobile Nav
              setMobileNavStatusTrigger(true);
            }
          } else {
            console.log("missing ref");
          }
        }}
      >
        <div className="mobile-nav-d1" ref={mobileNavD1}>
          &nbsp;
        </div>
        <div className="mobile-nav-d2" ref={mobileNavD2}>
          &nbsp;
        </div>
        <div className="mobile-nav-d3" ref={mobileNavD3}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default Navbar;
