import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Homepage from "./components/Homepage";
import { ErrorBoundary } from "./components/ErrorBoundary";
import MainMenu from "./components/MainMenu";
import AboutRonaify from "./components/AboutRonaify";
import Cryoskin from "./components/treatments/Cryoskin";
import SculptPod from "./components/treatments/SculptPod";
// import Salon from "./components/Salon";
import Success from "./components/Success";
import BookingHome from "./components/application/BookingHome";
import BookingOptions from "./components/application/BookingOptions";
import Joey from "./components/Joey";
import SculptPodDisclosure from "./components/application/SculptPodDisclosure";
import PaymentOptions from "./components/application/PaymentOptions";
import AccountCreator from "./components/application/AccountCreator";
import Login from "./components/application/Login";
import LoginPartTwo from "./components/application/LoginPartTwo";
import Dashboard from "./components/application/Dashboard";
import Payments from "./components/application/Payments";
import SalonV2 from "./components/treatments/SalonV2";
import Gallery from "./components/Gallery";
import Scheduler from "./components/application/Scheduler";
import BuySuperCredits from "./components/application/BuySuperCredits";
import AppointmentEditor from "./components/application/AppointmentEditor";
import AppointmentViewer from "./components/application/AppointmentViewer";

const router = createBrowserRouter([
  // public routes
  {
    path: "/",
    element: <Homepage />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/menu",
    element: <MainMenu />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/unleashed-beauty",
    element: <AboutRonaify />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/cryoskin",
    element: <Cryoskin />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/sculptpod",
    element: <SculptPod />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/salon",
    element: <SalonV2 />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/success",
    element: <Success />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/booking",
    element: <BookingHome />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/booking-options",
    element: <BookingOptions />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/joey",
    element: <Joey />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/sculpt-pod-disclosure",
    element: <SculptPodDisclosure />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/payment-options",
    element: <PaymentOptions />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/create-account",
    element: <AccountCreator />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <Login />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login-part-two",
    element: <LoginPartTwo />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/payments",
    element: <Payments />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/scheduler",
    element: <Scheduler />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/buy-supercredits",
    element: <BuySuperCredits />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/appointment-editor",
    element: <AppointmentEditor />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/appointment-viewer",
    element: <AppointmentViewer />,
    loader: async () => {
      // try {
      // const authStatus = await checkAuthenticated();
      return false;
      // } catch (error) {
      // throw new Error("no return value from checkAuthenticated...");
      // }
    },
    errorElement: <ErrorBoundary />,
  },
]);

const App = () => {
  const handleScroll = (e) => {
    const contactTrigger = document.querySelector(".contact-trigger");

    if (contactTrigger) {
      const treatmentsNav = document.querySelector(".treatments-nav");
      const contactNav = document.querySelector(".contact-nav");

      if (window.scrollY >= contactTrigger.offsetTop) {
        // highlight contact
        if (treatmentsNav && contactNav) {
          treatmentsNav.classList.remove("constant-underline");
          contactNav.classList.add("constant-underline");
        }
        // if (treatmentsMobileNav && contactMobileNav) {
        //   console.log("switch-gold-text to contact");
        //   treatmentsMobileNav.classList.remove("gold-text");
        //   contactMobileNav.classList.add("gold-text");
        // }
      } else {
        // highlight treatments
        if (treatmentsNav && contactNav) {
          contactNav.classList.remove("constant-underline");
          treatmentsNav.classList.add("constant-underline");
        }
        // if (treatmentsMobileNav && contactMobileNav) {
        //   contactMobileNav.classList.remove("gold-text");
        //   treatmentsMobileNav.classList.add("gold-text");
        // }
      }
    }
  };

  document.addEventListener("scroll", handleScroll);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
