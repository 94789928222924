import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import MainGrid from "./MainGrid";
import ContactForm from "./ContactForm";
import StupidElement from "./StupidElement";
import MobileNav from "./MobileNav";
import viewContact from "../util/viewContact";
import SpecialNotice from "./SpecialNotice";

const MainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [loadSpecialNotice, setLoadSpecialNotice] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".main-menu");

    // console.log(location);

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // console.log(location.state);

    // console.log("checking where it came from");
    // console.log(location.state.from);
    if (
      location.state &&
      location.state.from &&
      location.state.from === "contact-mobile-nav"
    ) {
      viewContact(navigate);
    } else if (top) {
      top.scrollIntoView();
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="fade-in main-menu">
      {loadSpecialNotice && (
        <SpecialNotice setLoadSpecialNotice={setLoadSpecialNotice} />
      )}
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <MainGrid />

      <div
        className="instagram-element"
        onClick={() => {
          window.open("https://www.instagram.com/_ronaify/", "_blank");
        }}
      >
        <img src="/insta-glyph.png" alt="instagram" />
        <p className="contact-trigger" id="contact">
          _ronaify
        </p>
      </div>

      <ContactForm />
      <div className="mobile-contact-girl"></div>
      <div className="legal-text d-flex justify-content-center align-items-center mb-3 large-top-margin">
        <div>Website by:&nbsp;</div>
        <a href="https://www.bayhawk.cc" target={"_blank"} rel="noreferrer">
          <div className="bayhawk-icon"></div>
        </a>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"menu"} />}
    </div>
  );
};

export default MainMenu;
