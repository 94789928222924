import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import { kickUnauthorized } from "../../util/kickUnauthorized";
import { DateTime } from "luxon";

const Scheduler = () => {
  const navigate = useNavigate();

  const apptDate = useRef();

  const [sculptPodFullCredits, setSculptPodFullCredits] = useState([]);
  const [sculptPodCurrentCredits, setSculptPodCurrentCredits] = useState(0);
  const [viewSculptPodScheduler, setViewSculptPodScheduler] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedService, setSelectedService] = useState("none-selected");

  const checkTimes = async function (timesToCheck) {
    try {
      // console.log(apptDate.current.value, timesToCheck);
      const res = await axios.post(
        "/api/v1/availability-filter",
        { date: apptDate.current.value, times: timesToCheck },
        axiosConfig
      );
      if (res && res.data && res.data.filteredTimes) {
        // console.log("setting");
        // console.log(res.data.filteredTimes);
        setAvailableTimes(Array.from(res.data.filteredTimes));
      } else {
        throw new Error("error deleting unavailable appointment times...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // here I want to check Authenticated
    (async function () {
      try {
        const res = await axios.post(
          "/api/v1/check-authenticated",
          {},
          axiosConfig
        );

        // console.log(res.data);

        if (!res.data.success) {
          throw new Error("Unauthorized request!");
        } else if (res.data.userType) {
          if (res.data.userType === "external") {
            setSculptPodFullCredits(res.data.sculptPodCredits);

            // setViewClientDashboard(true);
          } else if (res.data.userType === "internal") {
            // setViewUserDashboard(true);
          }
        }
      } catch (error) {
        console.log(error.message);
        kickUnauthorized("", navigate);
      }
    })();

    // console.log(location);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mt-5 pb-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="medium-large-text mt-3">Ronaify Scheduler</div>

        <select
          className="gallery-selector mt-3"
          onChange={(e) => {
            switch (e.target.value) {
              case "none-selected":
                break;
              case "cryoslimming":
                break;
              case "cryotoning":
                break;
              case "cryofacial":
                break;
              case "sculptpod":
                setViewSculptPodScheduler(true);
                setSelectedService("sculptpod");
                break;
              default:
                break;
            }
          }}
          value={selectedService ? selectedService : "none-selected"}
        >
          <option value="none-selected">Select Service</option>

          <option value="cryoslimming">CryoSlimming&reg;</option>

          <option value="cryotoning">CryoToning&reg;</option>

          <option value="cryofacial">CryoFacial</option>
          <option value="sculptpod">Sculpt Pod</option>
        </select>
        {/* separate this into a separate component */}
        {viewSculptPodScheduler && (
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 pb-5">
            <div className="medium-text">Select Date:</div>
            <input
              type="date"
              className="gallery-selector mt-3"
              ref={apptDate}
              onChange={(e) => {
                if (!sculptPodFullCredits) {
                  return;
                } else {
                  // console.log(sculptPodFullCredits);
                }
                if (
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                  ).getTime() -
                    14500000 >
                  new Date(e.currentTarget.value).getTime()
                ) {
                  alert("this date is in the past!");
                  setAvailableTimes([]);
                  setSculptPodCurrentCredits(0);
                  return;
                }

                const parsedSPCredits = [];
                const effectiveAfterNow = [];
                const expiredBeforeNow = [];

                sculptPodFullCredits.forEach((sPCr) => {
                  // console.log("iteration");
                  const { effectiveDate, expirationDate } = sPCr;
                  const now = new Date(e.currentTarget.value).toLocaleString();
                  // console.log(`NOW: ${now.getTime()}`);
                  if (
                    new Date(effectiveDate).getTime() - 63100000 <
                      new Date(now).getTime() &&
                    new Date(expirationDate).getTime() > new Date(now).getTime()
                  ) {
                    parsedSPCredits.push(sPCr);
                  } else {
                    if (
                      new Date(effectiveDate).getTime() - 63100000 >
                      new Date(now).getTime()
                    ) {
                      effectiveAfterNow.push(sPCr);
                    }

                    if (
                      new Date(expirationDate).getTime() <
                      new Date(now).getTime()
                    ) {
                      // console.log(expirationDate);
                      expiredBeforeNow.push(sPCr);
                    }
                  }
                });

                console.log("expired before now:", expiredBeforeNow.length);
                console.log("effective after now:", effectiveAfterNow.length);
                // console.log(effectiveAfterNow);

                console.log(new Date(e.currentTarget.value));
                // console.log(
                //   new Date(
                //     new Date(effectiveAfterNow[0].effectiveDate).getTime() -
                //       63100000
                //   )
                // );
                // console.log("parsedSPCredits.length = ", parsedSPCredits.length);
                // console.log(e.currentTarget.value);

                if (parsedSPCredits.length === 0) {
                  setSculptPodCurrentCredits(0);
                  setAvailableTimes([]);

                  const currentDate = new Date();
                  const currentYear = currentDate.getFullYear();
                  const currentMonth = currentDate.getMonth();
                  const currentDayOfMonth = currentDate.getDate();

                  const selectedDate = new Date(e.currentTarget.value);
                  const selectedYear = selectedDate.getFullYear();
                  const selectedMonth = selectedDate.getMonth();
                  const selectedDayOfMonth = selectedDate.getDate() + 1;

                  if (
                    currentYear === selectedYear &&
                    currentMonth === selectedMonth &&
                    currentDayOfMonth === selectedDayOfMonth
                  ) {
                    alert("No appointments available today!");
                  } else {
                    alert(
                      "Scheduling for this date can be done after associated subscription payment or by using Super Credits"
                    );
                  }

                  return;
                } else {
                  let subCrCt = 0;

                  const reduced = [];

                  for (let i = 0; i < parsedSPCredits.length; i++) {
                    if (!parsedSPCredits[i].superCredit) {
                      if (subCrCt < 10) {
                        subCrCt++;
                        reduced.push(parsedSPCredits[i]);
                      } else {
                        continue;
                      }
                    } else {
                      reduced.push(parsedSPCredits[i]);
                    }
                  }

                  setSculptPodCurrentCredits(reduced.length);
                }

                const newTimesArr = [];

                // calculate available times

                // for demo purposes, we use a naive implementation

                let beginning = new Date(`${apptDate.current.value}T09:00:00`);

                if (beginning.getDay() === 0) {
                  window.alert("Ronaify is closed on Sundays!");
                  setAvailableTimes([]);
                  return;
                }

                newTimesArr.push(beginning);

                const addAvailableTime = setInterval(function () {
                  let next = new Date(beginning);

                  next.setMinutes(beginning.getMinutes() + 30);

                  // console.log(beginning);
                  // console.log(next);

                  if (
                    next.getTime() >
                    new Date(`${apptDate.current.value}T17:30:00`).getTime()
                  ) {
                    //   console.log(next, "should be after 5:30pm");

                    const prettyTimes = [];

                    newTimesArr.forEach((t) => {
                      let splitUp = t.toLocaleString().split(":");

                      let correctHour = splitUp[0].split(", ")[1];
                      let correctMinutes = splitUp[1];
                      let amOrPm = splitUp[2].split(" ")[1];

                      prettyTimes.push(
                        `${correctHour}:${correctMinutes} ${amOrPm}`
                      );
                    });

                    // setAvailableTimes(prettyTimes);

                    //   console.log(newTimesArr);
                    checkTimes(prettyTimes);
                    clearInterval(addAvailableTime);
                  } else {
                    //   console.log(next, "should be before 5:30pm");

                    newTimesArr.push(next);
                    beginning = next;
                  }
                }, 28);
                //   }, 1000);
              }}
            />
            <div className="medium-text mt-3">
              Credits Available:{" "}
              {sculptPodCurrentCredits ? sculptPodCurrentCredits : 0}
            </div>
            {availableTimes &&
            availableTimes.length &&
            availableTimes.length > 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="service-module">
                  <p className="medium-text">Available Times:</p>
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {availableTimes.map((t, i) => (
                      <div
                        className="button-small center-text m-3"
                        key={i}
                        onClick={() => {
                          const doIt = window.confirm(
                            `Schedule this appointment?`
                          );

                          if (doIt) {
                            // issue command to server to create appointment
                            (async function () {
                              try {
                                // will need to update to send service type identifier (sculpt, cryo, etc.)
                                const res = await axios.post(
                                  "/api/v1/create-new-appointment",
                                  { date: apptDate.current.value, time: t },
                                  axiosConfig
                                );

                                if (res && res.data && res.data.success) {
                                  navigate("/dashboard");
                                } else {
                                  throw new Error(
                                    "could not create new appointment!"
                                  );
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            })();
                          } else {
                            console.log("declined");
                          }
                        }}
                      >
                        {t}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div>No times available for this date!</div>
            )}
          </div>
        )}
        <div
          className="button-medium fixed-300-width center-text mt-3 mb-5"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          &lt;&nbsp;Go Back
        </div>
      </div>
    </div>
  );
};

export default Scheduler;
