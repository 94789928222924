import React, { useEffect, useRef, useState } from "react";
import * as EmailValidator from "email-validator";
import { useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";

import axios from "axios";
import { axiosConfig } from "../util/axiosConfig";

const ContactForm = () => {
  const navigate = useNavigate();

  const userMsg = useRef(null);
  const bestMethod = useRef(null);
  const firstName = useRef(null);
  const lastName = useRef(null);
  const phoneNumber = useRef(null);
  const emailAddress = useRef(null);

  const [validPersonalEmail, setValidPersonalEmail] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className="contact-form-parent">
      <div>&nbsp;</div>
      <div className="contact-form">
        <h2>Contact Us!</h2>
        <div
          className="borderless"
          onClick={() => {
            window.open(
              "https://www.google.com/maps/place/353+Mariano+Bishop+Blvd,+Fall+River,+MA+02721/@41.6730302,-71.1670565,17z/data=!3m1!4b1!4m6!3m5!1s0x89e4ff376947e14f:0x3c020922435954c5!8m2!3d41.6730262!4d-71.1644816!16s%2Fg%2F11bw412qxy?entry=ttu",
              "_blank"
            );
          }}
        >
          <div className="d-flex">
            <p>🏠&nbsp;&nbsp;</p>
            <div>
              {" "}
              <p>353 Mariano Bishop Blvd. (Auto Gals building)</p>
              <p>Fall River, MA 02721</p>
            </div>
          </div>
        </div>

        <a className="borderless" href="tel:+15085670120">
          <div className="d-flex">
            <p>📱&nbsp;&nbsp;</p>
            <div>
              {" "}
              <p>(508) 567-0120</p>
            </div>
          </div>
        </a>

        <div className="print-text borderless">Any message for us?</div>
        <textarea
          className="print-text"
          placeholder="type here..."
          ref={userMsg}
        ></textarea>
        <div className="print-text  borderless">
          What is the BEST way to contact you?
        </div>
        <select className="print-text  p-1" ref={bestMethod}>
          <option>Text</option>
          <option>Call</option>
          <option>E-Mail</option>
        </select>
        <div className="print-text  borderless">Your Contact Info:</div>
        <input
          type="text"
          className="print-text  p-1"
          placeholder="First Name"
          ref={firstName}
        />
        <input
          type="text"
          className="print-text  p-1"
          placeholder="Last Name"
          ref={lastName}
        />
        <input
          type="tel"
          className="print-text  p-1"
          placeholder="Phone Number"
          ref={phoneNumber}
          onChange={(e) => {
            if (e.currentTarget.value) {
              const enteredValue = e.currentTarget.value;

              const numbersRegex = /[0-9]/gi;

              const numbersOnlyMatches = enteredValue.match(numbersRegex);

              if (numbersOnlyMatches) {
                if (
                  e.currentTarget.value[e.currentTarget.value.length - 1] ===
                  ")"
                ) {
                  e.currentTarget.value = `${e.currentTarget.value[1]}${e.currentTarget.value[2]}`;
                } else if (e.currentTarget.value.length === 10) {
                  e.currentTarget.value = `(${e.currentTarget.value[1]}${e.currentTarget.value[2]}${e.currentTarget.value[3]}) ${e.currentTarget.value[6]}${e.currentTarget.value[7]}${e.currentTarget.value[8]}`;
                }

                const numbersOnly = numbersOnlyMatches.join("");
                if (numbersOnly.length > 3 && numbersOnly.length < 6) {
                  const numbersOnlyArr = numbersOnly.split("");
                  const firstThree = [];
                  const rest = [];
                  numbersOnlyArr.forEach((n, idx) => {
                    if (idx <= 2) {
                      firstThree.push(n);
                    } else {
                      rest.push(n);
                    }
                  });

                  e.currentTarget.value = `(${firstThree.join("")}) ${rest.join(
                    ""
                  )}`;
                } else if (numbersOnly.length > 6 && numbersOnly.length < 11) {
                  const numbersOnlyArr = numbersOnly.split("");
                  const firstThree = [];
                  const secondThree = [];
                  const rest = [];
                  numbersOnlyArr.forEach((n, idx) => {
                    if (idx <= 2) {
                      firstThree.push(n);
                    } else if (idx > 2 && idx < 6) {
                      secondThree.push(n);
                    } else if (idx >= 6) {
                      rest.push(n);
                    }
                  });

                  e.currentTarget.value = `(${firstThree.join(
                    ""
                  )}) ${secondThree.join("")}-${rest.join("")}`;
                } else if (numbersOnly.length >= 11) {
                  e.currentTarget.value = `(${e.currentTarget.value[1]}${e.currentTarget.value[2]}${e.currentTarget.value[3]}) ${e.currentTarget.value[6]}${e.currentTarget.value[7]}${e.currentTarget.value[8]}-${e.currentTarget.value[10]}${e.currentTarget.value[11]}${e.currentTarget.value[12]}${e.currentTarget.value[13]}`;
                }
              }
            }
          }}
        />
        <input
          type="email"
          className="print-text  p-1"
          placeholder="E-Mail Address"
          ref={emailAddress}
          onKeyUp={(e) => {
            if (EmailValidator.validate(e.currentTarget.value)) {
              setValidPersonalEmail(true);
            } else {
              setValidPersonalEmail(false);
            }
          }}
        />
        <button
          className="button-main print-text margin-none mb-3 special-button"
          id="submit-appt-btn"
          onClick={(e) => {
            // e.currentTarget.style.display = "none";

            let missingFields = "|";

            bestMethod.current.value === "" &&
              (missingFields = missingFields + "| contact method |");
            firstName.current.value === "" &&
              (missingFields = missingFields + "| first name |");
            lastName.current.value === "" &&
              (missingFields = missingFields + "| last name |");
            phoneNumber.current.value === "" &&
              (missingFields = missingFields + "| phone number |");
            emailAddress.current.value === "" &&
              (missingFields = missingFields + "| email address |");

            missingFields = missingFields + "|";

            if (missingFields !== "||") {
              window.alert(
                `One or more of the following required fields is missing: ${missingFields}`
              );
            } else {
              // console.log("submit-me");

              (async function () {
                try {
                  // This is where we create the lead and send it to CRM
                  const data = {
                    message: userMsg.current.value,
                    method: bestMethod.current.value,
                    first: firstName.current.value,
                    last: lastName.current.value,
                    phone: phoneNumber.current.value,
                    email: validPersonalEmail ? emailAddress.current.value : "",
                  };
                  // THIS IS THE BEST WAY TO USE JSON.STRINGIFY
                  // console.log(JSON.stringify(data, null, "\t"));
                  const res = await axios
                    .post("/api/v1/ronaify-dot-com-lead", data, axiosConfig)
                    .catch((err) => {
                      throw new Error("could not post");
                    });
                  if (res.data.success) {
                    navigate("/success", {
                      state: {},
                    });
                  } else {
                    throw new Error("server error");
                  }
                } catch (error) {
                  // thisButton.style.display = "flex";
                  console.log(error.message);
                  window.alert(error.message);
                  window.location.reload();
                }
              })();
            }
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
