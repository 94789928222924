import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import StupidElement from "./StupidElement";
import viewContact from "../util/viewContact";

const Joey = () => {
  // const navigate = useNavigate();
  // const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".joey-page");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="joey-page">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="container">
        <div className="d-flex justify-content-center">
          {" "}
          <img src="/JOEY-BW.png" alt="joey-tribute" className="joey-bw" />
        </div>

        <h1 className="medium-text black-text mt-5 justify">
          In honor of my beloved son Joey, here at Ronaify, we strive to bring
          back what we lost and remind people of their true worth in this world.
          We hope to use his memory and his spirit to fuel the beauty inside of
          everyone. Have compassion for ourselves and each other - let there be
          Peace On Earth!
        </h1>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"joey"} />}
    </div>
  );
};

export default Joey;
