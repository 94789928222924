//
//
//
//
//

// THIS PAGE NEEDS TO KNOW IF THE USER IS ALREADY LOGGED IN OR NOT.  IF THEY ARE LOGGED IN, IT NEEDS TO KNOW WHETHER THEY ALREADY HAVE AN ACTIVE SUBSCRIPTION TO THE CURRENT PACKAGE
// we now have a first draft of this - 7/6/23

//
//
//
//
//

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import viewContact from "../../util/viewContact";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import MobileNav from "../MobileNav";

const BookingOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [numberOfSuperCredits, setNumberOfSuperCredits] = useState("1");

  useEffect(() => {
    (async function () {
      try {
        const res = await axios.post(
          "/api/v1/check-authenticated",
          {},
          axiosConfig
        );

        if (!location || !location.state || !location.state.packageName) {
          throw new Error("could not identify package name!");
        }

        if (res && res.data && res.data.success) {
          if (
            location.state.packageName === "12-month-sculpt-pod" ||
            location.state.packageName === "6-month-sculpt-pod"
          ) {
            let hasSubscriptionCredits = false;

            res.data.sculptPodCredits.forEach((cr) => {
              if (cr.superCredit) {
                return;
              } else {
                hasSubscriptionCredits = true;
              }
            });

            if (hasSubscriptionCredits) {
              navigate("/dashboard", {
                state: {
                  from: "booking-options",
                  packageName: location.state.packageName,
                },
              });
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    })();

    const top = document.querySelector(".booking-options-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="booking-options-top">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      {location &&
        location.state &&
        location.state.packageName &&
        (location.state.packageName === "12-month-sculpt-pod" ||
          location.state.packageName === "6-month-sculpt-pod") && (
          <div className="booking-conditional">
            {" "}
            <h1 className="medium-large-text">Sculpt Pod</h1>
            <h1 className="medium-text">
              {location.state.packageName === "12-month-sculpt-pod" && (
                <span>12</span>
              )}{" "}
              {location.state.packageName === "6-month-sculpt-pod" && (
                <span>6</span>
              )}{" "}
              Month Subscription
            </h1>
            {/* <p className="small-text">(10 sessions per month)</p> */}
            <div className="booking-conditional-buttons">
              {" "}
              <div
                className="book-now-button center-text"
                onClick={() => {
                  navigate("/payment-options", {
                    state: {
                      from: "booking-options",
                      packageName: location.state.packageName,
                    },
                  });
                }}
              >
                New Subscription
              </div>
              <div
                className="book-now-button center-text"
                onClick={() => {
                  navigate("/login", {
                    state: {
                      from: "booking-options",
                      packageName: location.state.packageName,
                    },
                  });
                }}
              >
                Already Bought
              </div>
              <div
                className="book-now-button center-text"
                onClick={() => {
                  navigate("/booking");
                }}
              >
                &lt;&nbsp;Go Back
              </div>
            </div>
          </div>
        )}

      {/* if they are already logged in, take them to the internal Super Credit Purchaser component.  Otherwise, ask them how many they want and them take them to the account creator (where there is an option to login if already have account).  If they are not logged in, the number and type of credits need to be added to location state */}
      {location &&
        location.state &&
        location.state.packageName &&
        location.state.packageName === "sculpt-pod-super-credits" && (
          <div className="container medium-text">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div>Sculpt Pod Super Credits</div>
              <div>$139 each</div>

              <div className="max-text-width tiny-text mt-5">
                Super Credits differ from subscription credits in that they can
                be used on any date in the future (at available times).
              </div>

              <div className="d-flex justify-content-between align-items-center max-text-width mt-5">
                <div className="">
                  How Many Sculpt Pod Super Credits would you like to purchase?
                </div>
                <select
                  className="gallery-selector"
                  value={numberOfSuperCredits}
                  onChange={(e) => {
                    setNumberOfSuperCredits(e.currentTarget.value);
                  }}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>

              <div className="mt-5">
                <button
                  className="button-medium"
                  onClick={() => {
                    navigate("/booking");
                  }}
                >
                  &lt; Back
                </button>
                &nbsp;&nbsp;
                <button
                  className="button-medium"
                  onClick={(e) => {
                    navigate("/payment-options", {
                      state: {
                        from: "booking-options",
                        packageName: location.state.packageName,
                        numberOfSuperCredits: numberOfSuperCredits,
                      },
                    });
                  }}
                >
                  Next &gt;
                </button>
              </div>
            </div>
          </div>
        )}
      {mobileNavStatus && <MobileNav currentPage={"booking-options"} />}
    </div>
  );
};

export default BookingOptions;
