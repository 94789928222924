import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import MobileNav from "../MobileNav";
import viewContact from "../../util/viewContact";

const SculptPod = () => {
  // const navigate = useNavigate();
  // const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".cryoskin-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="cryoskin-top fade-in">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="section-heading section-heading-sculptpod section-heading-text mb-5">
        <span className="black-ronaify-box"> Sculpt Pod</span>
      </div>
      <div className="container ">
        <div className="photo-paragraph">
          <img
            src="/pod-content-1.png"
            alt="CryoSlimming"
            className="sculpt-pod-img"
          />
          <div className="info-text photo-paragraph-text">
            <p className="mt-3">
              The Sculpt Pod is the perfect non-surgical solution for unwanted
              fat, loose/aging skin, and cellulite. Our treatments are a
              holistic approach to calorie burning, detoxifying the body, and
              pain relief. You can burn up to 1000 calories in 30 minutes! Every
              session treats your whole body, holistically.
            </p>
          </div>
        </div>

        <div className="photo-paragraph photo-paragraph-2">
          <div className="info-text photo-paragraph-text photo-paragraph-text-2">
            <p className="mt-3">
              Our sculpt pod uses the highest form of infrared heat, light,
              massage, and vibration to slim, tone, and tighten your body.
              Benefits include:
            </p>
            <ul className="pod-benefits mt-3">
              <li>&nbsp;Weight Loss + Fat Reduction</li>
              <li>&nbsp;Skin Tightening</li>
              <li>&nbsp;Cellulite Removal</li>
              <li>&nbsp;Reproduction of Collagen</li>
              <li>&nbsp;Breaks Down Scar Tissue</li>
              <li>&nbsp;Improve Metabolism</li>
              <li>&nbsp;Accelerates the Natural Healing Process</li>
              <li>&nbsp;Improve Lymphatic Drainage and Circulation</li>
              <li>&nbsp;Reduce Stress + Fatigue</li>
              <li>&nbsp;Improve Neuropathy Symptoms + Rheumatoid Arthritis</li>
            </ul>
          </div>
          <img
            src="/pod-content-2.png"
            alt="CryoToning"
            className="sculpt-pod-img"
          />
        </div>

        <div className="photo-with-text photo-with-text-cryoskin">
          <img
            src="/pod-content-large.png"
            alt="cryoskin-ad"
            className="sculpt-pod-img"
          />
          <button
            className="infopage-button"
            onClick={() => {
              window
                .open("https://www.vagaro.com/ronaifybeautyboutique/", "_blank")
                ?.focus();
            }}
          >
            View Plans and Pricing
          </button>
        </div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"sculpt"} />}
    </div>
  );
};

export default SculptPod;
