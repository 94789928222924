import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  //   const location = useLocation();

  const [count, setCount] = useState("3");

  useEffect(() => {
    const top = document.querySelector(".ronaify-about-top");

    if (top) {
      top.scrollIntoView();
    }

    let count = 3;

    // setTimeout(function () {
    const counter = setInterval(function () {
      count--;

      setCount(count.toString());

      if (count === 0) {
        clearInterval(counter);
        navigate("/menu", { state: { from: "success-nav" } });
      }
    }, 999);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="ronaify-about-top fade-in pb-5 d-flex flex-column justify-content-center align-items-center full-viewport-height">
      <h1 className="large-text">Success!</h1>
      {count ? (
        <p className="medium-large-text center-text">
          Redirecting to Ronaify Main Menu in {count} seconds...
        </p>
      ) : (
        <p className="medium-large-text center-text">
          Redirecting to Ronaify Main Menu...
        </p>
      )}
    </div>
  );
};

export default Success;
