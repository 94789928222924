import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import MobileNav from "../MobileNav";
import viewContact from "../../util/viewContact";

const Cryoskin = () => {
  // const navigate = useNavigate();
  // const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".cryoskin-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="cryoskin-top fade-in">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="section-heading section-heading-cryoskin section-heading-text  mb-5">
        CRYOSKIN
      </div>
      <div className="container ">
        <div className="photo-paragraph">
          <img
            src="/cryoskin-content-1.png"
            alt="CryoSlimming"
            className="cryoskin-img"
          />
          <div className="info-text photo-paragraph-text">
            <h2 className="info-heading">CryoSlimming&reg;</h2>
            <p>
              CryoSlimming&reg; uses the application of alternating heat and
              cold temperatures in order to stimulate and encourage the natural
              process of releasing fat cells through the lymphatic system. The
              massage wand is applied using a specialized technique in a
              localized area.
            </p>
          </div>
        </div>

        <div className="photo-paragraph photo-paragraph-2">
          <div className="info-text photo-paragraph-text photo-paragraph-text-2">
            <h2 className="info-heading">CryoToning&reg;</h2>
            <p>
              CryoToning&reg; uses cold massage to smooth, lift, and firm skin,
              reduce the appearance of imperfections, and improve the skin's
              overall texture and appearance.
            </p>
          </div>
          <img
            src="/cryoskin-content-2.png"
            alt="CryoToning"
            className="cryoskin-img"
          />
        </div>
        <div className="photo-paragraph">
          <img
            src="/cryoskin-content-3.png"
            alt="CryoFacial"
            className="cryoskin-img"
          />
          <div className="info-text photo-paragraph-text">
            <h2 className="info-heading">CryoFacial</h2>
            <p>
              CryoFacials use cold temperatures to increase blood flow and
              oxygen, which helps to reduce the signs of aging while also
              providing brightening, contouring, and lifting. CryoFacials are a
              natural, non-invasive way to look younger and more radiant.
            </p>
          </div>
        </div>

        <div className="photo-with-text photo-with-text-cryoskin">
          <img
            src="/jake-cryoskin.png"
            alt="cryoskin-ad"
            className="cryoskin-img"
          />
          <button
            className="infopage-button"
            onClick={() => {
              window
                .open("https://www.vagaro.com/ronaifybeautyboutique/", "_blank")
                ?.focus();
            }}
          >
            View Plans and Pricing
          </button>
        </div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"cryoskin"} />}
    </div>
  );
};

export default Cryoskin;
