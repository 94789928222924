import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import viewContact from "../../util/viewContact";
import axios from "axios";

const LoginPartTwo = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const authCodeRef = useRef("");

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    if (!location || !location.state || !location.state.username) {
      navigate("/menu", { state: {} });
    } else {
      console.log(location);
    }

    // if this is a logged-in user, redirect to dashboard !important

    // eslint-disable-next-line
  }, []);

  const loginPartTwoHandler = async (u, ac) => {
    try {
      if (!location || !location.state || !location.state.username) {
        throw new Error("missing necessary information!");
      }
      const res = await axios.post("/api/v1/auth", {
        enteredAuthCode: authCodeRef.current.value,
        username: location.state.username,
      });

      if (res && res.data && res.data.success) {
        // console.log("success!", res.data);
        // here, if we came from regular login we should go to the dashboard, but if we came from account creation we should go to payments for the package they were trying to select
        if (location.state.from && location.state.from === "account-creator") {
          navigate("/payments", {
            state: {
              disclosureAgreed: true,
              user: res.data.user,
              from: "login-part-two",
              packageName: location.state.packageName,
              paymentType: location.state.paymentType,
              numberOfSuperCredits: location.state.numberOfSuperCredits,
            },
          });
        } else if (
          location.state.from &&
          location.state.from === "login-part-one" &&
          location.state.packageName &&
          location.state.paymentType
        ) {
          navigate("/payments", {
            state: {
              // user: res.data.user || null,
              from: "login-part-two",
              packageName: location.state.packageName,
              disclosureAgreed: location.state.disclosureAgreed || false,
              numberOfSuperCredits: location.state.numberOfSuperCredits || 0,
              paymentType: location.state.paymentType,
              user: res.data.user,
            },
          });
        } else {
          console.log(location.state);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.log(error);
      alert("could not log in!");
      navigate("/menu", { state: {} });
    }
  };

  return (
    <div className="">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="d-flex flex-column justify-content-center align-items-center mt-5">
        <input
          type="password"
          ref={authCodeRef}
          className="account-creation-field"
          placeholder="Auth Code"
        />

        <button onClick={loginPartTwoHandler} className="button-main mt-5">
          Enter
        </button>
      </div>
    </div>
  );
};

export default LoginPartTwo;
