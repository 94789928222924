import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import MobileNav from "../MobileNav";
import viewContact from "../../util/viewContact";

const SalonV2 = () => {
  // const navigate = useNavigate();
  // const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".hairsalon-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="pb-5 hairsalon-top fade-in">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />

      <div className="container mt-5 mb-5">
        <div className="photo-paragraph">
          <img src="/salon-content-2.png" alt="glamour" />
          <div className="info-text photo-paragraph-text">
            <h2 className="info-heading">Head Turning Results</h2>
            <p>
              Visit us and treat yourself to our complete salon. Ronaify offers
              a wide variety of services, including Haircuts, Color Corrections,
              Keratin Treatments, and many others.
            </p>
          </div>
        </div>

        <div className="photo-paragraph photo-paragraph-2">
          <div className="info-text photo-paragraph-text photo-paragraph-text-2">
            <p className="mt-3">
              Our professional staff is experienced, trained and certified in
              the latest salon techniqes and products. If you want a
              sophisticated updo, treatment for textured hair or luxurious
              blonde highlights, our specialists will provide you with expert
              service.
            </p>
          </div>
          <img src="/salon-content-1.png" alt="glamour-2" />
        </div>

        <div className="d-flex justify-content-center mb-5">
          <img src="/aidile-cover.png" className="gold-border" alt="glamour" />
        </div>
        <div className="d-flex justify-content-center large-text">Aidile's</div>
        <div className="d-flex justify-content-center medium-text">
          (401) 263-3344
        </div>

        <div className="d-flex justify-content-center mt-5">
          <div className="service-module service-module-wider gold-border p-3">
            <p className="medium-text">
              Aidile, a master colorist, specializes in custom color,
              highlighting, low lighting, balayage, ombre, color correction, and
              cutting styling services. With over 30 years experience, her
              expertise will ensure the perfect look is achieved. With her
              attention to detail & passion for the art of hair, she will create
              the perfect look for you.{" "}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          {" "}
          <button
            className="infopage-button"
            onClick={() => {
              window
                .open(
                  "https://booksy.com/en-us/695524_aidiles-in-fall-river_hair-salon_22097_fall-river#ba_s=sh_1",
                  "_blank"
                )
                .focus();
            }}
          >
            Book Appointment
          </button>
        </div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"salon"} />}
    </div>
  );
};

export default SalonV2;
