import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BuySuperCredits = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [numberOfSuperCredits, setNumberOfSuperCredits] = useState("1");

  return (
    <div className="container medium-text mt-5">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div>Sculpt Pod Super Credits</div>
        <div>$139 each</div>

        <div className="max-text-width tiny-text mt-5">
          Super Credits differ from subscription credits in that they can be
          used on any date in the future (at available times).
        </div>

        <div className="d-flex justify-content-between align-items-center max-text-width mt-5">
          <div className="">
            How Many Sculpt Pod Super Credits would you like to purchase?
          </div>
          <select
            className="gallery-selector m-3"
            value={numberOfSuperCredits}
            onChange={(e) => {
              setNumberOfSuperCredits(e.currentTarget.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>

        <div className="mt-5">
          <button
            className="button-medium"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            &lt; Back
          </button>
          &nbsp;&nbsp;
          <button
            className="button-medium"
            onClick={(e) => {
              navigate("/payment-options", {
                state: {
                  from: "buy-user-supercredits",
                  packageName: "sculpt-pod-super-credits",
                  numberOfSuperCredits: numberOfSuperCredits,
                },
              });
            }}
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuySuperCredits;
