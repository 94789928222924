import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
// import { useNavigate } from "react-router-dom";
import viewContact from "../../util/viewContact";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const SculptPodDisclosure = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    const top = document.querySelector(".ronaify-about-top");

    if (top) {
      top.scrollIntoView();
    }

    // console.log(location);

    // eslint-disable-next-line
  }, []);

  const checkAgreed = () => {
    const checkbox = document.getElementById("sculpt-pod-disclosure-checkbox");
    const nextBtn = document.getElementById("sculpt-pod-disclosure-next-btn");
    if (checkbox.checked) {
      nextBtn.classList.remove("button-main-disabled");
    } else {
      nextBtn.classList.add("button-main-disabled");
    }
  };

  return (
    <div className="pb-5">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="container">
        <h2 className="m-3 center-text">Notice</h2>
        <p className="m-3 justify">
          If you do not follow the instructions for your sessions, you will not
          see BEST results. For best results, please come 3 days per week with
          one day in between sessions. Best results are with 10-16 Sessions.
          Most will start seeing results within 3 sessions but need more then
          just 3 sessions for long term results. We recommend drinking one
          bottled water before and one bottled water after your sessions. All
          sessions are 30 minutes long. This includes time to change clothes. If
          you are more then five minutes late to your session, you will have to
          reschedule. YOU MAY ONLY USE THIS MACHINE ONCE EVERY 24 HOURS PER
          STATE LAW. WE MAKE NO CLAIMS THAT THIS MACHINE CURES ANY DISEASE. I
          HAVE READ AND FULLY AGREE TO SCULPT POD, INC'S PRIVACY POLICY LISTED
          ON THIS WEBSITE. NEVER USE THE SCULPT POD IF YOU ARE PREGNANT, BREAST
          FEEDING OR HAVE A HEART CONDITION. . Use caution with persons
          sensitive to light and heat. Do not use if you're taking medication
          that makes you sensitive to light or heat. ALL jewelry must be
          removed. NO METAL IMPLANTS OR UNDERWIRE. Titanium implants are
          approved. Do not put Hot Cream on breast or sensitive areas like
          nipples. Only use Hot Cream on fatty areas such a stomach, legs and
          buttocks. If Hot Cream gets under "folds" of skin, wipe off with baby
          wipe or towel. Do not get the Hot Cream on face or in eyes. Make sure
          you're not sensitive or allergic to any ingredients in the hot cream.
          Certain medications or other applied lotion/products on skin prior the
          session may have a photosensitive reaction. Do not use the Sculpt Pod
          after receiving a laser hair removal or any invasive or noninvasive
          treatments. Please remember that by sweating in the Sculpt Pod your
          body eliminates toxins and this could cause a rash. Clients who do not
          hydrate properly can be prone to such reaction. Each Sculpt Pod has a
          weight limit of 300 pounds. We suggest women to wear a sports bra and
          panties, Men just underwear. CONTRADICTIONS: EPILESPY, ACTIVE CANCER,
          BROKEN BONES, INTOXICATION, INFECTIOUS OR CONTAGIOUS SKIN CONDITIONS,
          ERYTHEMA, PACEMAKERS AND DEFIBRILLATORS OR IN POOR HEALTH. You must
          have a doctors consent before using the Sculpt Pod if you have
          diabetes, using medication such as diuretics, barbiturates or beta
          blockers, hemophiliacs or individuals with sensitive skin or poor
          blood circulation. This machine WILL FADE tattoos. We cannot guarantee
          results. Results will vary from person to person. PLEASE DO NOT EAT
          ONE HOUR BEFORE OR ONE HOUR AFTER YOUR SESSION. PLEASE DRINK ONE
          BOTTLED WATER AFTER YOUR SESSION TO FLUSH FAT AND TOXINS OUT. Any and
          all Coupons -GROUPONS -Facebook Offers- Living Social ect. CAN ONLY BE
          PURCHASED AND USED ONCE EVERY 60 DAYS. *** FOR THE SCULPT POD PRO, IT
          IS ONE TIME PURCHASE -NEW CLIENTS ONLY. IF I SUBMIT TO HAVING A BEFORE
          AND AFTER PICTURE TAKEN, I AM FULLY AWARE AND AGREE THAT IT CAN BE
          USED ON OUR WEBSITE AND SOCIAL MEDIA PLATFORMS. YOUR FACE, NAME AND
          INFORMATION WILL NOT BE USED. ON BEHALF OF SCULPT POD, INC WE DO NOT
          ALLOW ANY REFUNDS OR TRANSFERRING SESSIONS BETWEEN CUSTOMERS. Get
          Ready To Get Fit! Thank you for your business!{" "}
        </p>
        <p className="m-3 justify">
          By signing this form I am fully aware of the Polices, Waiver and
          Release Form and the Privacy Policy of Sculpt Pod, INC. I am signing
          my name to this legal and binding document. I agree to all Polices
          Waiver and Release forms and release Sculpt Pod, INC (The Sculpt Pod)
          and all its affiliates, its officers, owners and employees from any
          and all injury, loss or damages associated with activities
          participated in this event. I acknowledge and understand the consent
          of this document. I am aware that it is legal and binding and I sign
          it out of my own free will. I am aware of Sculpt Pod, INC's No Refund
          No Transfer Policy. I understand that if I am on the monthly
          membership program at any given location that there are no refunds and
          I am signing up for either 6 months or 12 months depending on which
          plan I choose.
        </p>
        <div className="m-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="test-checkbox"
            id="sculpt-pod-disclosure-checkbox"
            onClick={checkAgreed}
          />{" "}
          <label
            htmlFor="sculpt-pod-disclosure-checkbox"
            className="medium-text"
            onClick={checkAgreed}
          >
            I Agree
          </label>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center pb-5">
          {" "}
          <div
            className="button-main button-main-disabled center-text"
            id="sculpt-pod-disclosure-next-btn"
            onClick={() => {
              (async function () {
                try {
                  // here, I want to check if they are already logged in.  If so, we'll go to the Payment Simulator.  Otherwise, we'll go to the Account Creator.

                  const res = await axios
                    .post("/api/v1/check-authenticated", {}, axiosConfig)
                    .catch((err) => {
                      console.log(err.message);
                    });

                  if (res && res.data && res.data.success) {
                    navigate("/payments", {
                      state: {
                        from: "sculpt-pod-disclosure",
                        packageName: location.state.packageName,
                        paymentType: location.state.paymentType,
                        numberOfSuperCredits:
                          location.state.numberOfSuperCredits,
                        disclosureAgreed: true,
                        user: res.data.user,
                      },
                    });
                  } else {
                    navigate("/create-account", {
                      state: {
                        from: "sculpt-pod-disclosure",
                        packageName: location.state.packageName,
                        paymentType: location.state.paymentType,
                        numberOfSuperCredits:
                          location.state.numberOfSuperCredits,
                        disclosureAgreed: true,
                      },
                    });
                  }
                } catch (error) {
                  console.log(error.message);
                }
              })();
            }}
          >
            Next
          </div>
          <div
            className="button-main center-text mb-5"
            onClick={() => {
              navigate("/booking");
            }}
          >
            &lt;&nbsp;Go Back
          </div>
        </div>
      </div>
    </div>
  );
};

export default SculptPodDisclosure;
