import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const AppointmentEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const apptDate = useRef();

  const [viewSculptPodScheduler, setViewSculptPodScheduler] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);

  useEffect(() => {
    console.log(location.state);

    // eslint-disable-next-line
  }, []);

  const checkTimes = async function (timesToCheck) {
    try {
      // console.log(apptDate.current.value, timesToCheck);
      const res = await axios.post(
        "/api/v1/availability-filter",
        { date: apptDate.current.value, times: timesToCheck },
        axiosConfig
      );
      if (res && res.data && res.data.filteredTimes) {
        // console.log("setting");
        // console.log(res.data.filteredTimes);
        setAvailableTimes(Array.from(res.data.filteredTimes));
      } else {
        throw new Error("error deleting unavailable appointment times...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container pb-5">
      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        <div className="medium-large-text mt-5">Appointment Editor</div>

        {location.state.nextScheduledAppointment ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {" "}
            {location.state.nextScheduledAppointment.serviceName ===
              "sculpt-pod" && (
              <p className="small-medium-text mt-3">Sculpt Pod</p>
            )}
            {/* <p className="small-text mt-3">Monday, July 10 2023 - 10:30am</p> */}
            <p className="small-text mt-3">
              {location.state.prettyDate && location.state.prettyDate}
            </p>
          </div>
        ) : (
          <div></div>
        )}
        <button
          className="button-medium p-3 mt-3 fixed-300-width"
          onClick={() => {
            setViewSculptPodScheduler(true);
          }}
        >
          Reschedule
        </button>
        {viewSculptPodScheduler && (
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 pb-5">
            <div className="medium-text">Select Date:</div>
            <input
              type="date"
              className="gallery-selector"
              ref={apptDate}
              onChange={(e) => {
                if (
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                  ).getTime() -
                    14500000 >
                  new Date(e.currentTarget.value).getTime()
                ) {
                  alert("this date is in the past!");
                  setAvailableTimes([]);

                  return;
                }

                const parsedSPCredits = [];
                const effectiveAfterNow = [];
                const expiredBeforeNow = [];

                const newTimesArr = [];

                // calculate available times

                // for demo purposes, we use a naive implementation

                let beginning = new Date(`${apptDate.current.value}T09:00:00`);

                if (beginning.getDay() === 0) {
                  window.alert("Ronaify is closed on Sundays!");
                  setAvailableTimes([]);
                  return;
                }

                newTimesArr.push(beginning);

                const addAvailableTime = setInterval(function () {
                  let next = new Date(beginning);

                  next.setMinutes(beginning.getMinutes() + 30);

                  // console.log(beginning);
                  // console.log(next);

                  if (
                    next.getTime() >
                    new Date(`${apptDate.current.value}T17:30:00`).getTime()
                  ) {
                    //   console.log(next, "should be after 5:30pm");

                    const prettyTimes = [];

                    newTimesArr.forEach((t) => {
                      let splitUp = t.toLocaleString().split(":");

                      let correctHour = splitUp[0].split(", ")[1];
                      let correctMinutes = splitUp[1];
                      let amOrPm = splitUp[2].split(" ")[1];

                      prettyTimes.push(
                        `${correctHour}:${correctMinutes} ${amOrPm}`
                      );
                    });

                    // setAvailableTimes(prettyTimes);

                    //   console.log(newTimesArr);
                    checkTimes(prettyTimes);
                    clearInterval(addAvailableTime);
                  } else {
                    //   console.log(next, "should be before 5:30pm");

                    newTimesArr.push(next);
                    beginning = next;
                  }
                }, 28);
                //   }, 1000);
              }}
            />

            {availableTimes &&
            availableTimes.length &&
            availableTimes.length > 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="service-module">
                  <p className="medium-text">Available Times:</p>
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {availableTimes.map((t, i) => (
                      <div
                        className="button-small center-text m-3"
                        key={i}
                        onClick={() => {
                          const doIt = window.confirm(
                            `Schedule this appointment?`
                          );

                          if (doIt) {
                            // issue command to server to create appointment
                            (async function () {
                              try {
                                // will need to update to send service type identifier (sculpt, cryo, etc.)
                                const res = await axios.post(
                                  "/api/v1/reschedule-appt",
                                  {
                                    appointment:
                                      location.state.nextScheduledAppointment,
                                    date: apptDate.current.value,
                                    time: t,
                                  },
                                  axiosConfig
                                );

                                if (res && res.data && res.data.success) {
                                  if (
                                    location &&
                                    location.state &&
                                    location.state.from
                                  ) {
                                    if (
                                      location.state.from === "client-dashboard"
                                    ) {
                                      navigate("/dashboard", {
                                        state: {
                                          ...location.state,
                                          from: "appointment-editor",
                                        },
                                      });
                                    } else if (
                                      location.state.from ===
                                      "appointment-viewer"
                                    ) {
                                      navigate("/appointment-viewer", {
                                        state: {
                                          ...location.state,
                                          from: "appointment-editor",
                                        },
                                      });
                                    }
                                  } else {
                                    navigate("/dashboard", {
                                      state: {
                                        ...location.state,
                                        from: "appointment-editor",
                                      },
                                    });
                                  }
                                } else {
                                  throw new Error(
                                    "could not create new appointment!"
                                  );
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            })();
                          } else {
                            console.log("declined");
                          }
                        }}
                      >
                        {t}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div>No times available for this date!</div>
            )}
          </div>
        )}
        <button
          className="button-medium p-3 mt-3 fixed-300-width"
          onClick={() => {
            (async function () {
              try {
                const res = await axios.post(
                  "/api/v1/cancel-appointment",
                  {
                    _id: location.state.nextScheduledAppointment._id,
                    createdFromSuper:
                      location.state.nextScheduledAppointment.createdFromSuper,
                  },
                  axiosConfig
                );

                if (res && res.data && res.data.success) {
                  alert("success!");
                  if (
                    location &&
                    location.state &&
                    location.state.from &&
                    location.state.from === "client-dashboard"
                  ) {
                    navigate(`/dashboard`);
                  }
                } else {
                  throw new Error("unsuccessful response from server!");
                }
              } catch (error) {
                alert("Error cancelling Appointment!");
                if (
                  location &&
                  location.state &&
                  location.state.from &&
                  location.state.from === "client-dashboard"
                ) {
                  navigate(`/dashboard`);
                }
              }
            })();
          }}
        >
          Cancel Appointment
        </button>
        <button
          className="button-medium p-3 mt-3 fixed-300-width"
          onClick={() => {
            if (location && location.state && location.state.from) {
              if (location.state.from === "client-dashboard") {
                navigate("/dashboard", {
                  state: { ...location.state, from: "appointment-editor" },
                });
              }

              if (location.state.from === "appointment-viewer") {
                navigate("/appointment-viewer", {
                  state: { ...location.state, from: "appointment-editor" },
                });
              }
            }
          }}
        >
          &lt;&nbsp;Go Back
        </button>
      </div>
    </div>
  );
};

export default AppointmentEditor;
