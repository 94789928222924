import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as EmailValidator from "email-validator";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import { kickUnauthorized } from "../../util/kickUnauthorized";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import viewContact from "../../util/viewContact";
import MobileNav from "../MobileNav";

const AccountCreator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const firstName = useRef("");
  const lastName = useRef("");
  const phoneNumber = useRef("");
  const emailAddress = useRef("");
  const passWord = useRef("");

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [validPersonalEmail, setValidPersonalEmail] = useState(false);

  useEffect(() => {
    console.log(location.state);

    const top = document.querySelector(".create-account-top");

    if (top) {
      top.scrollIntoView();
    }

    if (validPersonalEmail) {
      // this is only here to satisfy eslint for now
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="create-account-top">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="container d-flex flex-column justify-content-center align-items-center pb-5">
        <h1 className="medium-text">Create Ronaify Account</h1>
        <div>
          <span>Already have account?&nbsp;&nbsp;</span>
          <span
            className="tiny-button"
            onClick={() => {
              // the login page may need any of these "pieces of state"
              navigate("/login", {
                state: {
                  disclosureAgreed: true,
                  from: "account-creator",
                  packageName: location.state.packageName,
                  numberOfSuperCredits: location.state.numberOfSuperCredits,
                  paymentType: location.state.paymentType,
                },
              });
            }}
          >
            Log&nbsp;In
          </span>
        </div>
        <div className="m-3 center-text">
          To purchase without creating an online account, call
          <span
            onClick={() => {
              document.getElementById("ronaify-tel").click();
            }}
            className="cursor-pointer"
          >
            &nbsp;(508)&nbsp;567-0120
          </span>
          <a className="display-none" id="ronaify-tel" href="tel:+15085670120">
            ronaify-phone
          </a>
        </div>
        <input
          type="text"
          className="account-creation-field mt-0"
          placeholder="First Name"
          ref={firstName}
        />
        <input
          type="text"
          className="account-creation-field"
          placeholder="Last Name"
          ref={lastName}
        />
        <input
          type="tel"
          className="account-creation-field"
          placeholder="Phone Number"
          ref={phoneNumber}
          onChange={(e) => {
            if (e.currentTarget.value) {
              const enteredValue = e.currentTarget.value;

              const numbersRegex = /[0-9]/gi;

              const numbersOnlyMatches = enteredValue.match(numbersRegex);

              if (numbersOnlyMatches) {
                if (
                  e.currentTarget.value[e.currentTarget.value.length - 1] ===
                  ")"
                ) {
                  e.currentTarget.value = `${e.currentTarget.value[1]}${e.currentTarget.value[2]}`;
                } else if (e.currentTarget.value.length === 10) {
                  e.currentTarget.value = `(${e.currentTarget.value[1]}${e.currentTarget.value[2]}${e.currentTarget.value[3]}) ${e.currentTarget.value[6]}${e.currentTarget.value[7]}${e.currentTarget.value[8]}`;
                }

                const numbersOnly = numbersOnlyMatches.join("");
                if (numbersOnly.length > 3 && numbersOnly.length < 6) {
                  const numbersOnlyArr = numbersOnly.split("");
                  const firstThree = [];
                  const rest = [];
                  numbersOnlyArr.forEach((n, idx) => {
                    if (idx <= 2) {
                      firstThree.push(n);
                    } else {
                      rest.push(n);
                    }
                  });

                  e.currentTarget.value = `(${firstThree.join("")}) ${rest.join(
                    ""
                  )}`;
                } else if (numbersOnly.length > 6 && numbersOnly.length < 11) {
                  const numbersOnlyArr = numbersOnly.split("");
                  const firstThree = [];
                  const secondThree = [];
                  const rest = [];
                  numbersOnlyArr.forEach((n, idx) => {
                    if (idx <= 2) {
                      firstThree.push(n);
                    } else if (idx > 2 && idx < 6) {
                      secondThree.push(n);
                    } else if (idx >= 6) {
                      rest.push(n);
                    }
                  });

                  e.currentTarget.value = `(${firstThree.join(
                    ""
                  )}) ${secondThree.join("")}-${rest.join("")}`;
                } else if (numbersOnly.length >= 11) {
                  e.currentTarget.value = `(${e.currentTarget.value[1]}${e.currentTarget.value[2]}${e.currentTarget.value[3]}) ${e.currentTarget.value[6]}${e.currentTarget.value[7]}${e.currentTarget.value[8]}-${e.currentTarget.value[10]}${e.currentTarget.value[11]}${e.currentTarget.value[12]}${e.currentTarget.value[13]}`;
                }
              }
            }
          }}
        />
        <input
          type="email"
          className="account-creation-field"
          placeholder="E-Mail Address"
          ref={emailAddress}
          onKeyUp={(e) => {
            if (EmailValidator.validate(e.currentTarget.value)) {
              setValidPersonalEmail(true);
            } else {
              setValidPersonalEmail(false);
            }
          }}
        />
        <input
          type="password"
          className="account-creation-field"
          placeholder="Password"
          ref={passWord}
        />

        <div
          className="button-medium mt-5 mb-5"
          onClick={() => {
            // naive validation

            if (
              !firstName.current.value ||
              !lastName.current.value ||
              !validPersonalEmail ||
              !phoneNumber.current.value ||
              !passWord.current.value
            ) {
              window.alert("One or more required fields is incomplete!");
            } else {
              //
              (async () => {
                await axios
                  .post(
                    "/api/v1/create-user",
                    {
                      firstName: firstName.current.value,
                      lastName: lastName.current.value,
                      userName: emailAddress.current.value,
                      password: passWord.current.value,
                      active: true,
                      userPhone: phoneNumber.current.value,
                      userType: "external",
                    },
                    axiosConfig
                  )
                  .then((res) => {
                    // console.log(res);
                    window.alert("Account Created!");
                    navigate("/login-part-two", {
                      state: {
                        disclosureAgreed: true,
                        from: "account-creator",
                        packageName: location.state.packageName,
                        paymentType: location.state.paymentType,
                        numberOfSuperCredits:
                          location.state.numberOfSuperCredits,
                        username: res.data.un,
                      },
                    });
                  })
                  .catch((err) => {
                    // ALWAYS DO THIS IN THE FRONT END
                    window.alert(
                      "Could not create account, please call (508) 567-0120 to proceed..."
                    );
                    console.log(err.message);
                    kickUnauthorized(err, navigate);
                  });
              })();
            }
          }}
        >
          Submit
        </div>
      </div>
      {mobileNavStatus && <MobileNav currentPage={"account-creator"} />}
    </div>
  );
};

export default AccountCreator;
