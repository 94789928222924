import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import StupidElement from "./StupidElement";
import MobileNav from "./MobileNav";
import viewContact from "../util/viewContact";

const Gallery = () => {
  //
  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [viewCryoSlimming, setViewCryoSlimming] = useState(false);
  const [viewCryoToning, setViewCryoToning] = useState(false);
  //
  useEffect(() => {
    const top = document.querySelector(".gallery-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);
  //
  return (
    <div className="gallery-top pb-5">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />

      <div className="container center-text">
        <select
          className="gallery-selector"
          onChange={(e) => {
            switch (e.target.value) {
              case "none-selected":
                setViewCryoToning(false);
                setViewCryoSlimming(false);
                break;
              case "cryoslimming":
                setViewCryoToning(false);
                setViewCryoSlimming(true);
                break;

              case "cryotoning":
                setViewCryoSlimming(false);
                setViewCryoToning(true);
                break;

              default:
                break;
            }
          }}
        >
          <option value="none-selected">Select Gallery</option>
          <option value="cryoslimming">CryoSlimming&reg;</option>
          <option value="cryotoning">CryoToning&reg;</option>
        </select>

        {viewCryoSlimming && (
          <div className="d-flex flex-wrap justify-content-evenly mt-5">
            <img src="/gallery/cryo-slim-1.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-2.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-3.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-4.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-5.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-6.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-7.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-8.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-9.png" alt="cryoslimming" />
            <img src="/gallery/cryo-slim-10.png" alt="cryoslimming" />
            <img
              src="/gallery/cryo-slim-11.png"
              alt="cryoslimming"
              className="mb-5"
            />
          </div>
        )}

        {viewCryoToning && (
          <div className="d-flex flex-wrap justify-content-evenly mt-5">
            <img src="/gallery/cryo-tone-1.png" alt="cryotoning" />
            <img src="/gallery/cryo-tone-2.png" alt="cryotoning" />
            <img src="/gallery/cryo-tone-3.png" alt="cryotoning" />
            <img src="/gallery/cryo-tone-4.png" alt="cryotoning" />
            <img src="/gallery/cryo-tone-5.png" alt="cryotoning" />
            <img src="/gallery/cryo-tone-6.png" alt="cryotoning" />
            <img
              src="/gallery/cryo-tone-7.png"
              alt="cryotoning"
              className="mb-5"
            />
          </div>
        )}
      </div>

      {mobileNavStatus && <MobileNav currentPage={"gallery"} />}
    </div>
  );
};

export default Gallery;
