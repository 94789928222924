import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientDashboard from "./ClientDashboard";
import { kickUnauthorized } from "../../util/kickUnauthorized";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [sculptPodSuperCredits, setSculptPodSuperCredits] = useState(0);
  const [sculptPodSubscriptionCredits, setSculptPodSubscriptionCredits] =
    useState(0);
  const [viewClientDashboard, setViewClientDashboard] = useState(false);
  const [viewUserDashboard, setViewUserDashboard] = useState(false);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [sculptPodSubBtnEnabled, setSculptPodSubBtnEnabled] = useState(false);

  useEffect(() => {
    // here I want to check Authenticated
    (async function () {
      try {
        const res = await axios.post(
          "/api/v1/check-authenticated",
          {},
          axiosConfig
        );

        // console.log(res);

        if (!res.data.success) {
          throw new Error("Unauthorized request!");
        } else if (res.data.userType) {
          if (res.data.userType === "external") {
            // console.log(res.data.sculptPodCredits);

            // if (
            //   location &&
            //   location.state &&
            //   location.state.packageName &&
            //   location.state.packageName === "sculpt-pod-super-credits"
            // ) {
            //   navigate("/buy-supercredits", {
            //     state: { packageName: "sculpt-pod-super-credits" },
            //   });
            // }

            const superCredits = [];
            const subCredits = [];
            Array.from(res.data.sculptPodCredits).forEach((spc) => {
              if (spc.superCredit) {
                superCredits.push(spc);
              } else {
                subCredits.push(spc);
              }
            });

            const futureSubCredits = [];

            subCredits.forEach((subCr) => {
              const { expirationDate } = subCr;
              const now = new Date();
              if (new Date(expirationDate).getTime() > now.getTime()) {
                futureSubCredits.push(subCr);
              }
            });

            if (!futureSubCredits.length) {
              setSculptPodSubBtnEnabled(true);
            }

            // these are numbers

            const parsedSubCredits = [];
            subCredits.forEach((subCr) => {
              const { effectiveDate, expirationDate } = subCr;
              const now = new Date();
              if (
                new Date(effectiveDate).getTime() < now.getTime() &&
                new Date(expirationDate).getTime() > now.getTime()
              ) {
                parsedSubCredits.push(subCr);
              }
            });

            if (parsedSubCredits.length > 10) {
              setSculptPodSubscriptionCredits(10);
            } else {
              setSculptPodSubscriptionCredits(parsedSubCredits.length);
            }

            setSculptPodSuperCredits(superCredits.length);

            const sorted = res.data.appointments;

            sorted.sort((a, b) => {
              if (
                new Date(a.appointmentDate).getTime() >
                new Date(b.appointmentDate).getTime()
              ) {
                return 1;
              } else {
                return -1;
              }
            });

            // here, we know all the appointments of this user

            const futureAppts = [];
            const pastAppts = [];

            if (sorted[0]) {
              const now = new Date().getTime();

              for (let n = 0; n < sorted.length; n++) {
                const appt = new Date(sorted[n].appointmentDate).getTime();

                if (now > appt) {
                  pastAppts.push(sorted[n]);
                } else {
                  futureAppts.push(sorted[n]);
                }
              }

              if (futureAppts[0]) {
                setNextAppointment(futureAppts[0]);
              }

              if (pastAppts[0]) {
                for (let x = 0; x < pastAppts.length; x++) {
                  if (pastAppts[x].status === "pending") {
                    // Here, it was a no-call, no-show.  Cancel it, don't restore the credit, and reload this page
                    (async function () {
                      try {
                        const res = await axios.post(
                          "/api/v1/cancel-no-show",
                          { apptToCancel: pastAppts[x] },
                          axiosConfig
                        );

                        if (res && res.data && res.data.success) {
                          console.log("marked cancelled!");
                          window.location.reload();
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    })();
                  }
                }
              }
            }

            // console.log(sorted);

            setViewClientDashboard(true);
          } else if (res.data.userType === "internal") {
            setViewUserDashboard(true);
          }
        }
      } catch (error) {
        console.log(error.message);
        kickUnauthorized("", navigate);
      }
    })();

    console.log(location);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {viewClientDashboard && (
        <ClientDashboard
          sculptPodSubscriptionCredits={sculptPodSubscriptionCredits}
          sculptPodSuperCredits={sculptPodSuperCredits}
          nextScheduledAppointment={nextAppointment}
          sculptPodSubBtnEnabled={sculptPodSubBtnEnabled}
        />
      )}
    </div>
  );
};

export default Dashboard;
