export const kickUnauthorized = (err, navigate) => {
  // if (
  //   err &&
  //   err.response &&
  //   err.response.status &&
  //   err.response.status === 401
  // ) {
  navigate("/menu", { state: { from: "kick-unauthorized" } });
  // }
};
