import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import MobileNav from "../MobileNav";
import viewContact from "../../util/viewContact";

const ClientDashboard = ({
  sculptPodSubscriptionCredits,
  sculptPodSuperCredits,
  nextScheduledAppointment,
  sculptPodSubBtnEnabled,
}) => {
  // const location = useLocation();
  const navigate = useNavigate();

  const [prettyDate, setPrettyDate] = useState(null);
  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  // console.log(nextScheduledAppointment);

  let cryoSlimmingCredits = 0;
  let cryoToningCredits = 0;
  let cryoFacialCredits = 0;

  useEffect(() => {
    if (nextScheduledAppointment && nextScheduledAppointment.appointmentDate) {
      const starter = new Date(
        nextScheduledAppointment.appointmentDate
      ).toLocaleString();

      if (starter) {
        setPrettyDate(
          `${starter.split(":")[0]}:${starter.split(":")[1]}${
            starter.split(":")[2].split(" ")[1]
          }`
        );
      }
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="pb-5">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="section-heading section-heading-dashboard section-heading-text  mb-5">
        <span className="black-ronaify-box">Dashboard</span>
      </div>
      <div className="container">
        {nextScheduledAppointment ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {" "}
            <p className="medium-text">Next Appointment:</p>
            {nextScheduledAppointment.serviceName === "sculpt-pod" && (
              <p className="small-medium-text mt-3">Sculpt Pod</p>
            )}
            {/* <p className="small-text mt-3">Monday, July 10 2023 - 10:30am</p> */}
            <p className="small-text mt-3">{prettyDate && prettyDate}</p>
            <div className="d-flex mt-3 mb-3">
              <div
                className="button-medium"
                onClick={() => {
                  navigate("/appointment-editor", {
                    state: {
                      nextScheduledAppointment,
                      prettyDate,
                      from: "client-dashboard",
                    },
                  });
                }}
              >
                Modify
              </div>
              <div className="m-3 mt-0 mb-0"></div>
              <div
                className="button-medium"
                onClick={() => {
                  navigate("/appointment-viewer");
                }}
              >
                View All
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {(sculptPodSubscriptionCredits ||
          sculptPodSuperCredits ||
          cryoSlimmingCredits ||
          cryoToningCredits ||
          cryoFacialCredits) &&
        (sculptPodSubscriptionCredits > 0 ||
          sculptPodSuperCredits > 0 ||
          cryoSlimmingCredits > 0 ||
          cryoToningCredits > 0 ||
          cryoFacialCredits > 0) ? (
          <div className="d-flex justify-content-center mt-3 mb-3">
            <div
              className="fixed-300-width button-medium center-text"
              onClick={() => {
                navigate("/scheduler");
              }}
            >
              + New Appointment
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="service-module">
            <div className="fixed-300-width small-text d-flex justify-content-center flex-column align-items-center black-background p-3">
              <div className="parent-width d-flex flex-column align-items-center">
                <span className="mb-3">Sculpt Pod:</span>
                <div className="d-flex parent-width justify-content-between mb-3">
                  <span>Subscription Credits:</span>
                  <span>
                    {sculptPodSubscriptionCredits
                      ? sculptPodSubscriptionCredits
                      : "0"}
                  </span>
                </div>
                {sculptPodSubBtnEnabled && (
                  <div
                    className="button-small mb-3"
                    onClick={() => {
                      navigate("/buy-supercredits");
                    }}
                  >
                    + Buy Subscription
                  </div>
                )}

                <div className="d-flex parent-width justify-content-between align-items-center mb-3">
                  <span>Super Credits:</span>
                  <span>
                    {sculptPodSuperCredits ? sculptPodSuperCredits : "0"}
                  </span>
                </div>

                <div
                  className="button-small mb-3"
                  onClick={() => {
                    navigate("/buy-supercredits", { from: "client-dashboard" });
                  }}
                >
                  + Buy Super Credits
                </div>
              </div>
            </div>
            <div className="fixed-300-width d-flex justify-content-between align-items-center mt-3">
              <span className="small-medium-text">
                CryoSlimming: {cryoSlimmingCredits ? cryoSlimmingCredits : "0"}
                &nbsp;
              </span>
              <span className="button-medium">+ Add</span>
            </div>
            <div className="fixed-300-width d-flex justify-content-between align-items-center mt-3">
              <span className="small-medium-text">
                CryoToning: {cryoToningCredits ? cryoToningCredits : "0"}&nbsp;
              </span>
              <span className="button-medium">+ Add</span>
            </div>
            <div className="fixed-300-width d-flex justify-content-between align-items-center mt-3">
              <span className="small-medium-text">
                CryoFacial: {cryoFacialCredits ? cryoFacialCredits : "0"}&nbsp;
              </span>
              <span className="button-medium">+ Add</span>
            </div>
          </div>
        </div>
      </div>

      {mobileNavStatus && <MobileNav currentPage={"client-dashboard"} />}
    </div>
  );
};

export default ClientDashboard;
