import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import viewContact from "../../util/viewContact";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);

  useEffect(() => {
    console.log(location.state);

    (async function () {
      try {
        // here, I want to check if they are already logged in.  If so, we'll go to the Payment Simulator.  Otherwise, we'll go to the Account Creator.

        const res = await axios
          .post("/api/v1/check-authenticated", {}, axiosConfig)
          .catch((err) => {
            console.log(err.message);
          });

        if (res && res.data && res.data.success) {
          navigate("/dashboard");
        }
      } catch (err) {
        console.log(err);
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div className="medium-text">Log In</div>
        <div className=" p-3">
          <input
            className="account-creation-field center-text "
            placeholder="username"
            id="un"
          />
        </div>
        <div className=" p-3">
          <input
            type="password"
            className="account-creation-field center-text "
            placeholder="password"
            id="pass"
          />
        </div>
        <div className="d-flex">
          <div
            className="button-medium m-3"
            onClick={() => {
              navigate("/menu");
            }}
          >
            Home
          </div>
          <div
            className="button-medium m-3"
            onClick={() => {
              //
              const username = document.getElementById("un").value;
              const password = document.getElementById("pass").value;

              (async function () {
                try {
                  const res = await axios.post(
                    "/api/v1/login",
                    { username, password },
                    axiosConfig
                  );
                  // console.log(res);
                  if (res && res.data && res.data.success) {
                    if (
                      location &&
                      location.state &&
                      location.state.packageName &&
                      location.state.paymentType
                    ) {
                      navigate("/login-part-two", {
                        state: {
                          disclosureAgreed:
                            location.state.disclosureAgreed || false,
                          from: "login-part-one",
                          numberOfSuperCredits:
                            location.state.numberOfSuperCredits || 0,
                          username: res.data.username,
                          packageName: location.state.packageName,
                          paymentType: location.state.paymentType,
                        },
                      });
                    } else {
                      console.log("no packageName and/or paymentType!");
                      navigate("/login-part-two", {
                        state: {
                          from: "login-part-one",
                          username: res.data.username,
                        },
                      });
                    }
                  } else {
                    throw new Error("unsuccessful or no response from server");
                  }
                } catch (error) {
                  alert("could not get auth code!");
                  window.location.reload();
                }
              })();
            }}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
