import React from "react";
// import { useNavigate } from "react-router-dom";

const SpecialNotice = ({ setLoadSpecialNotice }) => {
  //   const navigate = useNavigate();
  return (
    <div className="popup popup-trans">
      <div className="popup-body popup-body-evenly">
        <div className="scroll-me">
          <div className="d-flex flex-column justify-content-evenly align-items-center parent-height">
            <button
              className="button-medium print-text small-medium-text p-3 mb-5"
              id="back-to-inv-btn"
              onLoad={() => {
                const top = document.getElementById("back-to-inv-btn");
                top.scrollIntoView();
              }}
              onClick={() => {
                setLoadSpecialNotice(false);
              }}
            >
              Continue to <span className="gold-text">ronaify</span>
              .com
            </button>
            <img
              src="/independence-day.png"
              alt="july-4-greeting"
              className="mb-5"
            />
            <div className="small-medium-text p-3">
              Ronaify is closed in observance of Independence Day on 7/3/23 and
              7/4/23. We will re-open on Monday 7/5/23 at 9:00am!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialNotice;
