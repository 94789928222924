import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import StupidElement from "../StupidElement";
import MobileNav from "../MobileNav";
import viewContact from "../../util/viewContact";

const BookingHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const [mobileNavStatusTrigger, setMobileNavStatusTrigger] = useState(false);
  const [sculptExpanded, setSculptExpanded] = useState(true);
  const [cryoExpanded, setCryoExpanded] = useState(false);
  const [salonExpanded, setSalonExpanded] = useState(false);
  const [alerted, setAlerted] = useState(false);

  useEffect(() => {
    if (!alerted) {
      setAlerted(true);
      window.alert("This page is in DEVELOPMENT and does not work yet!");
      console.log(location);
    }

    const top = document.querySelector(".ronaify-about-top");

    if (top) {
      top.scrollIntoView();
    }

    if (mobileNavStatusTrigger) {
      const mobileNavElement = document.querySelector(".mobile-nav-full");

      if (mobileNavElement) {
        setTimeout(function () {
          mobileNavElement.style.setProperty(
            "animation",
            "pull-down 0.5s linear"
          );
          setMobileNavStatus(false);
          setMobileNavStatusTrigger(false);
        }, 400);
        mobileNavElement.style.setProperty("animation", "push-up 0.5s linear");
      }
    }

    // eslint-disable-next-line
  }, [mobileNavStatusTrigger]);

  return (
    <div className="booking-home">
      <Navbar
        setMobileNavStatus={setMobileNavStatus}
        setMobileNavStatusTrigger={setMobileNavStatusTrigger}
        viewContact={viewContact}
      />
      <StupidElement />
      <div className="booking-home-main-content container">
        <div className="d-flex justify-content-center align-items-center mb-3">
          <span>Already have account?&nbsp;&nbsp;</span>
          <span
            className="tiny-button"
            onClick={() => {
              // the login page may need any of these "pieces of state"
              navigate("/login", {
                state: {
                  from: "booking-home",
                },
              });
            }}
          >
            Log&nbsp;In
          </span>
        </div>
        <div
          className="sculpt-pod-button sculpt-pod-button-open"
          onClick={(e) => {
            if (
              Array.from(e.currentTarget.classList).includes(
                "sculpt-pod-button-closed"
              )
            ) {
              // console.log("open it");
              e.currentTarget.classList.remove("sculpt-pod-button-closed");
              e.currentTarget.classList.add("sculpt-pod-button-open");
              setSculptExpanded(true);
            } else {
              // console.log("close it");
              e.currentTarget.classList.remove("sculpt-pod-button-open");
              e.currentTarget.classList.add("sculpt-pod-button-closed");

              const sculptMovable = document.getElementById(
                "sculpt-services-movable"
              );

              if (sculptMovable) {
                sculptMovable.classList.remove("package-pull-down");
                sculptMovable.classList.add("package-push-up");
              }

              setTimeout(() => {
                setSculptExpanded(false);
              }, 450);
            }
          }}
        >
          <p>Sculpt Pod</p>
        </div>
        {sculptExpanded && (
          <div className="package-pull-down" id="sculpt-services-movable">
            <div className="small-text mt-3 booking-section-heading">
              Our Sculpt Pod is designed to provide you with the best possible
              results for your health and wellness. Help reduce inflammation,
              improve circulation, and reduce muscle and joint pain with
              innovative technology to deliver safe and effective treatment.
            </div>
            <div className="service-module-container">
              <div className="service-module" id="12-month-sculpt-pod">
                <div className="service-descriptor small-medium-text">
                  <div>12 Month Subscription</div>
                  <div>$259/mo</div>
                </div>

                <div className="d-flex justify-content-center">
                  <div
                    className="book-now-button center-text"
                    onClick={() => {
                      navigate("/booking-options", {
                        state: {
                          packageName: "12-month-sculpt-pod",
                        },
                      });
                    }}
                  >
                    Select
                  </div>
                </div>
              </div>

              <div className="service-module">
                <div className="service-descriptor small-medium-text">
                  <div>6 Month Subscription</div>
                  <div>$299/mo</div>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    className="book-now-button center-text"
                    onClick={() => {
                      navigate("/booking-options", {
                        state: {
                          packageName: "6-month-sculpt-pod",
                        },
                      });
                    }}
                  >
                    Select
                  </div>
                </div>
              </div>

              <div className="service-module">
                <div className="service-descriptor small-medium-text">
                  <div>Super Credits</div>
                  <div>$139&nbsp;each</div>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    className="book-now-button center-text"
                    onClick={() => {
                      navigate("/booking-options", {
                        state: {
                          packageName: "sculpt-pod-super-credits",
                        },
                      });
                    }}
                  >
                    Select
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="black-spacer">&nbsp;</div>
        <div
          className="cryo-services-button cryo-services-button-closed"
          onClick={(e) => {
            if (
              Array.from(e.currentTarget.classList).includes(
                "cryo-services-button-closed"
              )
            ) {
              // console.log("open it");
              e.currentTarget.classList.remove("cryo-services-button-closed");
              e.currentTarget.classList.add("cryo-services-button-open");
              setCryoExpanded(true);
            } else {
              // console.log("close it");
              e.currentTarget.classList.remove("cryo-services-button-open");
              e.currentTarget.classList.add("cryo-services-button-closed");

              const cryoMovable = document.getElementById(
                "cryo-services-movable"
              );

              if (cryoMovable) {
                cryoMovable.classList.remove("package-pull-down");
                cryoMovable.classList.add("package-push-up");
              }

              setTimeout(() => {
                setCryoExpanded(false);
              }, 450);
            }
          }}
        >
          <p>CRYOSKIN</p>
        </div>

        {cryoExpanded && (
          <div className="package-pull-down" id="cryo-services-movable">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
        )}

        <div className="black-spacer">&nbsp;</div>
        <div
          className="salon-services-button salon-services-button-closed"
          onClick={(e) => {
            if (
              Array.from(e.currentTarget.classList).includes(
                "salon-services-button-closed"
              )
            ) {
              // console.log("open it");
              e.currentTarget.classList.remove("salon-services-button-closed");
              e.currentTarget.classList.add("salon-services-button-open");
              setSalonExpanded(true);
            } else {
              // console.log("close it");
              e.currentTarget.classList.remove("salon-services-button-open");
              e.currentTarget.classList.add("salon-services-button-closed");

              const salonMovable = document.getElementById(
                "salon-services-movable"
              );

              if (salonMovable) {
                salonMovable.classList.remove("package-pull-down");
                salonMovable.classList.add("package-push-up");
              }

              setTimeout(() => {
                setSalonExpanded(false);
              }, 450);
            }
          }}
        >
          <p>Salon</p>
        </div>

        {salonExpanded && (
          <div className="package-pull-down" id="salon-services-movable">
            <div>Salon Service 1</div>
            <div>Salon Service 2</div>
            <div>Salon Service 3</div>
          </div>
        )}

        {mobileNavStatus && <MobileNav currentPage={"booking"} />}
      </div>
    </div>
  );
};

export default BookingHome;
