import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";

const Payments = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [stateDetails, setStateDetails] = useState([]);

  useEffect(() => {
    if (!location || !location.state) {
      navigate("/dashboard");
    }

    // well-formed state: {
    //   disclosureAgreed: true,
    //   from: "login-part-two",
    //   numberOfSuperCredits: "7",
    //   packageName: "sculpt-pod-super-credits",
    //   paymentType: "pay-now"
    //   user: {_id: '64bd6f7d124ee7904bbdabfc', firstName: 'Joe', lastName: 'Schmoe', userName: 'joe@schmoe.com', active: true, …}
    // }
    console.log(location);

    const localStateArr = [];

    const keys = Object.keys(location.state);

    const values = Object.values(location.state);

    for (let i = 0; i < keys.length; i++) {
      const myObj = {};
      if (keys[i] === "user") {
        myObj[keys[i]] = "{...User}";
      } else {
        myObj[keys[i]] = values[i];
      }

      localStateArr.push(myObj);
    }

    console.log(localStateArr);
    setStateDetails(localStateArr);

    // eslint-disable-next-line
  }, []);

  const issuePaymentAttempt = (locationState) => {
    // this page needs robust authentication
    // here's how it works:
    //
    // 1) Stripe Completion URL: /?uid=9r9fnm3q98rrm9q34jn...
    //
    // 2) Need to get Success or Failure data from Stripe somehow.  Temporarily, here, we receive this hard-coded data as "type".
    //
    // 3) Need to update User with success or failure and issue credits to account for packageType found in state

    switch (locationState.packageName) {
      case "12-month-sculpt-pod":
        if (!locationState.disclosureAgreed) {
          console.log("locationState.disclosureAgreed is not true...");
          return false;
        }
        // update User and state
        // this will have to be a protected route, but for now we just do it quick and dirty

        (async function () {
          try {
            const res = await axios.post(
              "/api/v1/12-month-sculpt-pod-success",
              {
                user: locationState.user,
                paymentType: locationState.paymentType,
              },
              axiosConfig
            );

            if (res && res.data && res.data.success) {
              window.alert("Success!");

              navigate("/dashboard", {
                state: { ...locationState, updatedUser: res.data.updated },
              });
            } else {
              throw new Error("no response from server!");
            }
          } catch (error) {
            window.alert("Unsuccessful!");
          }
        })();

        break;

      case "sculpt-pod-super-credits":
        if (!locationState.disclosureAgreed) {
          console.log("locationState.disclosureAgreed is not true...");
          return false;
        }
        // update User and state
        // this will have to be a protected route, but for now we just do it quick and dirty

        (async function () {
          try {
            const res = await axios.post(
              "/api/v1/sculpt-pod-super-credits-success",
              {
                user: locationState.user,
                paymentType: locationState.paymentType,
                numberOfSuperCredits: locationState.numberOfSuperCredits,
              },
              axiosConfig
            );

            if (res && res.data && res.data.success) {
              window.alert("Success!");

              navigate("/dashboard", {
                state: { ...locationState, updatedUser: res.data.updated },
              });
            } else {
              throw new Error("no response from server!");
            }
          } catch (error) {
            window.alert("Unsuccessful!");
          }
        })();

        break;

      default:
        break;
    }
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center medium-text mt-5">
      {/* <p>
        This is where we will need to have a way of taking credit card payments,
        such as using the{" "}
        <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
          Stripe API
        </a>
      </p> */}

      {/* <p>
        For now, we can manually issue the following events as if we were
        Stripe:
      </p> */}
      {/*all these buttons go to stripe resolution urls.  check if stripe can send url query strings*/}

      <div>Payment Simulator</div>

      <div className="tiny-text max-text-width mt-3">
        In real life, the user will now be directed to a screen on which they
        can enter their payment details and confirm their purchase. Once that
        process is complete (whether the payment was successful or not), the
        user will be directed to their Dashboard, (which will display accurately
        updated account details). Here, we simulate this functionality.
      </div>

      {stateDetails &&
        stateDetails.map((z, idx) => {
          return <div key={idx}>{JSON.stringify(z, null, 2)}</div>;
        })}

      <button
        className="button-medium max-text-width small-medium-text mt-3"
        onClick={() => {
          issuePaymentAttempt({ ...location.state, from: "payments" });
        }}
      >
        Successful payment
      </button>
      <button className="button-medium max-text-width small-medium-text mt-3">
        Unsuccessful payment
      </button>

      <div
        className="button-medium center-text p-3 mt-5"
        onClick={() => {
          navigate("/booking");
        }}
      >
        &lt;&nbsp;Go Back
      </div>
    </div>
  );
};

export default Payments;
